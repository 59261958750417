export const data =[
    {
        id:1,
        title:"TYPE OF COFFEE",
        content:"Looking at its layout.The point of coffee store.",
        img:"./images/img-1.png"
    },
    {
        id:2,
        title:"BEAN VARIETIES",
        content:"Looking at its layout.The point of coffee store.",
        img:"./images/img-2.png"
    },
    {
        id:3,
        title:"COFFEE & PASTRY",
        content:"Looking at its layout.The point of coffee store.",
        img:"./images/img-3.png"
    },
    {
        id:4,
        title:"COFFEE TO GO",
        content:"Looking at its layout.The point of coffee store.",
        img:"./images/img-4.png"
    },
]